import React from 'react';
import ModalR from 'react-responsive-modal';
import {
    NSContext, NSProductCard, imgDefaultBase64
} from 'aqlrc';
import routes, { Link } from '../routes';
import BundleProduct from './BundleProduct';
import { withI18next } from '../lib/withI18n';


class ProductCard extends NSProductCard {
    render() {
        const { from, mode, t, type, value } = this.props;
        const { openModal, product } = this.state;
        const { props } = this.context;
        if (!props) { return null; }
        const {
            lang, query, routerLang, taxDisplay, urlLang
        } = props;

        if (!product || !product._id) {
            return <p>{ t('product-card:load_error') } ({type}: {value})</p>
        }
        const product_route = routes.findAndGetUrls(product.canonical);

        let imgDefault = imgDefaultBase64;
        let imgAlt = '';
        if (product && product.images && product.images.length) {
            const foundImg = product.images.find((img) => img.default);
            if (foundImg) {
                imgDefault = foundImg._id !== 'undefined' ? `/images/products/104x110/${foundImg._id}/${product.slug[lang]}${foundImg.extension}` : imgDefault;
                imgAlt = foundImg.alt ? foundImg.alt : '';
            } else {
                imgDefault = product.images[0]._id !== 'undefined' ? `/images/products/104x110/${product.images[0]._id}/${product.slug[lang]}${product.images[0].extension}` : imgDefault;
                imgAlt = product.images[0].alt ? product.images[0].alt : '';
            }
        }

        // Pictos
        const pictos = [];
        if (product.pictos) {
            product.pictos.forEach((picto) => {
                if (pictos.find((p) => p.location === picto.location) !== undefined) {
                    pictos.find((p) => p.location === picto.location).pictos.push(picto);
                } else {
                    const cardinals = picto.location.split('_');
                    const style = { top: 0, left: 0 };
                    if (cardinals.includes('RIGHT')) {
                        style.left = 'inherit';
                        style.right = 0;
                    }
                    if (cardinals.includes('BOTTOM')) {
                        style.top = 'inherit';
                        style.bottom = 0;
                    }
                    if (cardinals.includes('CENTER')) {
                        style.left = '50%';
                        style.transform = 'translate(-50%, 0)';
                    }
                    if (cardinals.includes('MIDDLE')) {
                        style.top = '50%';
                        style.transform = 'translate(0, -50%)';
                    }
                    pictos.push({ location: picto.location, style, pictos: [picto] });
                }
            });
        }

        const price = product.price.ati.special >= 0 ? product.price.ati.special : product.price.ati.normal;

        return (
            <>
                <div className={`product-box${mode === 'small' ? ' product-box--small' : ''}`}>
                    <div className="product__inner">
                        <Link href={product_route.urls.href} as={product_route.urls.as}>
                            <a className="product__image">
                                <img itemProp="image" src={imgDefault} alt={imgAlt} loading="lazy" />
                            </a>{/* <!-- /.product__image --> */}
                        </Link>

                        <h5 className="product__title" title={product.name}>{product.name}</h5>{/* <!-- /.product__title --> */}

                        {
                        // <div className="product__rating">
                        //     <div className="rating">
                        //         <ul>
                        //             {
                        //                 [...Array(5)].map((el, idx) => (
                        //                     <li key={idx}>
                        //                         <svg width="11px" height="11px" viewBox="0 0 7 7" xmlns="http://www.w3.org/2000/svg">
                        //                             <path d="M3.5 5.804L1.337 7l.413-2.533L0 2.674l2.418-.37L3.5 0l1.082 2.304L7 2.674 5.25 4.467 5.663 7z" fill={idx < Math.round(product.reviews.average) ? '#FDBC58' : '#787878'} fillRule="evenodd" />
                        //                         </svg>
                        //                     </li>
                        //                 ))
                        //             }
                        //         </ul>

                        //         <span className="rating__meta">{product.reviews.reviews_nb ? `${product.reviews.average}/5` : 'Aucun avis'}</span>
                        //     </div>{/* <!-- /.rating --> */}
                        // </div>{/* <!-- /.product__rating --> */}
                        }

                        <div className="product__actions">
                            {
                                product.stock && product.stock.status !== 'epu' && (
                                    <button type="button" className="product__btn" onClick={product.type === 'bundle' ? this.onOpenModal : this.addToCart}>
                                        <i className="ico-cart-btn" />
                                    </button>
                                )
                            }

                            <Link href={product_route.urls.href} as={product_route.urls.as}>
                                <a className="product__btn">
                                    <i className="ico-search-btn" />
                                </a>
                            </Link>
                        </div>{/* <!-- /.product__actions --> */}

                        <div className="product__prices">
                            {
                                product.price.ati.special >= 0 && (<span className="product__price-old">{product.price.ati.normal.toFixed(2)} &euro;</span>)
                            }

                            <span className="product__price">{price.toFixed(2)} &euro;</span>
                        </div>{/* <!-- /.product__prices --> */}

                        <div className="product__tags">
                            {
                                pictos ? pictos.map((picto) => (
                                    <div className="product_picto" style={picto.style} key={picto.location + Math.random()}>
                                        {
                                            picto.pictos && picto.pictos.map((p) => <img src={`/images/picto/32x32-70/${p.pictoId}/${p.image}`} alt={p.title} title={p.title} key={p._id} />)
                                        }
                                    </div>
                                )) : ''
                            }
                        </div>{/* <!-- /.product__tags --> */}
                    </div>{/* <!-- /.product__inner --> */}
                </div>{/* <!-- /.product-box --> */}
                <ModalR
                    animationDuration={0} classNames={{ modal: 'popup__container', overlay: 'popup active' }}
                    open={openModal} onClose={this.onCloseModal} center
                >
                    {
                        product.type === 'bundle' ? (
                            <div className="modifier-popup__wrap">
                                <h3 className="modifier-popup__header">{t('product-card:composeMenu')}</h3>
                                <form ref={(form) => this.formMenu = form}>
                                    <div className="form__body">
                                        <BundleProduct product={product} />

                                        <div className="product-price">
                                            <strong>{(product.price.ati.normal || 0).toFixed(2)} €</strong>
                                        </div>{/* <!-- /.price --> */}
                                    </div>{/* <!-- /.popup__body --> */}
                                    <div className="form-footer">
                                        <button type="button" className="btn btn--red btn-cart" onClick={this.addToCart} aria-label={t('product-card:ajoutPanier')}>
                                            <i className="ico-shopping-cart-white" />
                                            <span>{t('product-card:ajoutPanier')}</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <>
                                <h3 className="popup__title">Ce produit a été ajouté à votre panier :</h3>{/* <!-- /.popup__title --> */}
                                <div className="popup__body">
                                    <div className="product-simple">
                                        <figure className="product__image">
                                            <img src={imgDefault} alt={imgAlt} />
                                        </figure>{/* <!-- /.product__image --> */}

                                        <h4 className="product__title">{this.state.selectedQty} x {product.name}</h4>{/* <!-- /.product__title --> */}

                                        <div className="product__actions">
                                            <button type="button" className="btn btn--with-icon btn--default" onClick={this.onCloseModal}>
                                                <span className="btn__icon">
                                                    <i className="ico-caret-left-grey" /> <i className="ico-caret-left-grey-hover" />
                                                </span>

                                                CONTINUER MES ACHATS
                                            </button>

                                            <Link route="cart" params={{ lang: routerLang }}>
                                                <a className="btn btn--with-icon btn--dark">
                                                    <span className="btn__icon">
                                                        <i className="ico-cart-large" /> <i className="ico-cart-large-hover" />
                                                    </span>

                                                    VOIR MON PANIER
                                                </a>
                                            </Link>
                                        </div>{/* <!-- /.product__actions --> */}
                                    </div>{/* <!-- /.product-simple --> */}
                                </div>{/* <!-- /.popup__body --> */}
                            </>
                        )
                    }
                </ModalR>
            </>
        );
    }

    static contextType = NSContext;

    static defaultProps = {
        includeCss : true,
        mode       : 'normal',
        product    : {
            _id    : '',
            images : [],
            name   : 'Undefined',
            price  : {
                et  : { normal: -1 },
                ati : { normal: -1 }
            },
            description1 : { title: '', text: '' },
            description2 : { title: '', text: '' },
            reviews      : {}
        },
        gridDisplay : true
    };
}

export default withI18next(['product-card', 'product'])(ProductCard);
