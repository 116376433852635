import React from 'react';
import { NSContext, NSSearchBar } from 'aqlrc';
import { withI18next } from '../lib/withI18n';

/**
 * Login - Barre de recherche (surcharge NSSearchBar)
 * @return {React.Component}
 */

class SearchBar extends NSSearchBar {
    render() {
        const { button, placeholder, t } = this.props;
        const { query } = this.state;
        return (
            <div className="search">
                <form onSubmit={(e) => this.searchProducts(e)}>
                    <label htmlFor="q" className="hidden">Search</label>
                    <input type="search" ref={this.search} name="q" id="q" placeholder="Que recherchez-vous ?" className="search__field" onChange={(e) => this.setState({ query: e.target.value })} value={query} />
                    <button type="submit" value="GO" className="search__btn"> <i className="ico-search" /> </button>
                </form>
            </div>
        );
    }

    static contextType = NSContext;
}

export default withI18next([])(SearchBar);
