import React from 'react';
import { NSMenu } from 'aqlrc';

/**
 * NSMenu - Affiche le bloc du menu correspondant au code passé en prop (ns-code)
 * @prop ns-code: (string) nom du menu à afficher
 * @return {React.Component}
 */

class Menu extends NSMenu {
    recursive(menu, l, slugs = []) {
        const {
            gNext, classnavulli, classnavulliactive, classnavullia, levelmax
        } = this.props;
        const Link = (gNext && gNext.Link) || undefined;
        const {
            lang, routerLang, urlLang, currentUrl
        } = this.context.props;
        if (levelmax && l >= levelmax) return '';
        return menu.children.map((section) => {
            // Gestion du slug recursif : cat1/cat2/[...]
            if (slugs.length - 1 >= l) {
                slugs.splice(l);
            }

            if ((section.action === 'catalog' && !section.slug) || (section.action === 'page' && (section.pageSlug === undefined || section.pageSlug === ''))) return '';
            if (section.action === 'catalog' && (!section.slug[lang] || section.slug[lang] === '')) return <li><a href="/">Non translated section</a></li>;

            let classLi = classnavulli;
            if (currentUrl.indexOf(`${section.pageSlug}`) > -1) {
                classLi = classLi ? ` ${classnavulliactive}` : classnavulliactive;
            }

            if (section.action === 'page') {
                slugs.push(section.pageSlug);
                let anchor = false;
                if (section.pageSlug.indexOf('#') > -1) {
                    anchor = true;
                }
                return (
                    <li
                        key={`${section._id}_${this.props['ns-code']}`}
                        className={classLi}
                    >
                        {
                            Link !== undefined
                                ? (
                                    anchor ? (
                                        <Link route={`${urlLang}/${section.pageSlug}`}>
                                            <a className={classnavullia}><span>{section.name}</span></a>
                                        </Link>
                                    ) : (
                                        <Link route="staticI18n" params={{ _slug: section.pageSlug || 'home',  lang: routerLang }}>
                                            <a className={classnavullia}><span>{section.name}</span></a>
                                        </Link>
                                    )
                                )
                                : <a className={classnavullia} href={`${urlLang}/${section.pageSlug}`}><span>{section.name}</span></a>
                        }
                        { section.children !== undefined && section.children.length ? <ul>{this.recursive(section, l + 1)}</ul> : '' }
                    </li>
                );
            }
            slugs.push(section.slug[lang]);

            let img = '';
            if (section.img) {
                const match = section.img.match(/\/([^\/]*)\.(.*)$/);
                if (match) {
                    img = `/images/category/34x56/${section._id}/${match[1]}.${match[2]}`;
                }
            }

            return (
                <li
                    key={`${section._id}_${this.props['ns-code']}`}
                    className={classLi}
                >
                    {
                        Link !== undefined
                            ? (
                                <Link route="categoryI18n" params={{ _slug: [...slugs] || '', page: undefined,  lang: routerLang }}>
                                    <a className={classnavullia}>
                                        {img && <img src={img} alt={section.alt} />}
                                        {section.name}
                                    </a>
                                </Link>
                            )
                            : <a href={`${urlLang}/c/${slugs.join('/')}`} className={classnavullia}><span>{section.name}</span></a>
                    }

                    { section.children !== undefined && section.children.length ? <ul>{this.recursive(section, l + 1, slugs)}</ul> : '' }
                </li>
            );
        });
    }
}

export default Menu;
