import React from 'react';
import { NSButtonLoginAccount, NSContext } from 'aqlrc';
import { withI18next } from '../lib/withI18n';
import { Link } from '../routes';

/*
Ce composant affiche le bouton "Mon compte" avec un lien vers la page "auth" si l'utilisateur n'est pas connecté ou "account" dans le cas contraire
*/

class ButtonLoginAccount extends NSButtonLoginAccount {
    render() {
        const { mode } = this.props;
        const { props } = this.context;
        if (!props) { return null; }
        const { routerLang, user } = props;
        return (
            <Link route={user ? 'account' : 'auth'} params={{ lang: routerLang }}>
                <a>
                    {
                        mode === 'mobile' ? <><i className="ico-lock-blue" />Compte</> : <><i className="ico-lock" /> Mon Compte</>
                    }
                </a>
            </Link>
        );
    }

    static contextType = NSContext;
}

export default withI18next([])(ButtonLoginAccount);
