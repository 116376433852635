import React from 'react';
import { NSCartProductCounter, NSContext } from 'aqlrc';
import { withI18next } from '../lib/withI18n';
import { Link } from '../routes';


class CartProductCounter extends NSCartProductCounter {
    render() {
        const { mode } = this.props;
        const { cart } = this.state;
        const { props } = this.context;
        if (!props) { return null; }
        const { routerLang } = props;
        return (
            <Link route="cart" params={{ lang: routerLang }}>
                <a>
                    {
                        mode === 'mobile' ? (
                            <>
                                <i className="ico-cart-blue" />Panier
                            </>
                        ) : (
                            <>
                                <i className="ico-cart" /> <span className="nav__inner">Mon Panier <small>{cart && cart.items && cart.items.filter((item) => !item.typeDisplay).length > 0 ? `${cart.items.filter((item) => !item.typeDisplay).length} article${cart.items.filter((item) => !item.typeDisplay).length > 1 ? 's' : ''}` : 'Aucun article'}</small></span>
                            </>
                        )
                    }

                </a>
            </Link>
        );
    }

    static contextType = NSContext;
}

export default withI18next(['common'])(CartProductCounter);
