import React from 'react';
import { NSBundleProduct } from 'aqlrc';
import { withI18next } from '../lib/withI18n';

/**
 * NSBundleProduct - Affiche la composition d'un produit bundle
 * @prop product: (object) Le produit
 * @return {React.Component}
 */

class BundleProduct extends NSBundleProduct {
    render() {
        const { product } = this.props;
        return (
            product.bundle_sections && product.bundle_sections.map((section, index) => (
                <React.Fragment key={index}>
                    <link rel="stylesheet" href="/static/css/filters.css" />
                    {section.type === 'MULTIPLE' && <h4>Entre {section.minSelect} & {section.maxSelect} {section.title}</h4>}
                    {section.type !== 'MULTIPLE' && <h4>{section.title}</h4>}
                    <div className="section" style={{ width: '100%' }}>
                        {
                            section.displayMode !== 'SELECT' && section.products
                                ? section.products.filter((prd) => prd.id.active).map((prd, index2) => {
                                    const _img = prd.id.images && prd.id.images.length > 0 ? prd.id.images.find((img) => img.default === true) || prd.id.images.sort((a, b) => a.position - b.position)[0] : { url: 'medias/no-image.png' };
                                    return (
                                        <div className="form-group" key={index2}>
                                            <div className="checkbox checkbox--radio">
                                                {section.displayMode === 'RADIO_BUTTON' && <input type="radio" id={`${prd.id._id}_${section.ref}`} className="custom-control-input" name={section.ref} value={prd.id._id} required={section.isRequired} defaultChecked={section.products.length < 2} disabled={prd.id.stock.status === 'epu' || !prd.id.stock.orderable} />}
                                                {section.type === 'MULTIPLE' && <input type="checkbox" id={`${prd.id._id}_${section.ref}`} className="custom-control-input" name={section.ref} value={prd.id._id} defaultChecked={section.products.length < 2} disabled={prd.id.stock.status === 'epu' || !prd.id.stock.orderable} />}

                                                <label className="custom-control-label" htmlFor={`${prd.id._id}_${section.ref}`} style={{ width: '100px', height: '70px' }}>
                                                    <img src={`/${_img.url}`} alt="" />
                                                </label>

                                                <span>{prd.id.name}</span>
                                            </div>{/* <!-- /.checkbox --> */}
                                        </div>
                                    );
                                }) : (
                                    <div>
                                        <select className="form-control" name={section.ref} id="">
                                            <option value="">--</option>
                                            {
                                                section.products && section.products.map((prd) => (
                                                    <option key={prd.id._id} value={prd.id._id} disabled={prd.id.stock.status === 'epu' || !prd.id.stock.orderable}>{prd.id.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                )
                        }
                    </div>
                </React.Fragment>
            ))
        );
    }
}

export default withI18next([])(BundleProduct);
