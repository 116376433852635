import React from 'react';
import { NSNewsletter, NSToast, updateNewsletterUser } from 'aqlrc';
import { withI18next } from '../lib/withI18n';

class Newsletter extends NSNewsletter {
    handleSubmit = async (e) => {
        e.preventDefault();
        if (!this.state.email) return;
        try {
            await updateNewsletterUser(this.state.email, 'DefaultNewsletter', true);
            NSToast.success('common:success_subscribe');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                NSToast.error(err.response.data.message);
            } else {
                NSToast.error('common:error_occured');
                console.error(err);
            }
        }
        this.setState({ email: '' });
    }

    render() {
        const { email } = this.state;
        const { t } = this.props;
        return (
            <div className="subscribe">
                <div className="shell">
                    <div className="subscribe__inner">
                        <button type="button" className="btn-scroll-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <i className="ico-caret-up" />
                        </button>

                        <div className="subscribe__entry">
                            <p>Recevez toutes les promotions et actus en cours</p>
                        </div>{/* <!-- /.subscribe__entry --> */}

                        <div className="subscribe__form">
                            <form onSubmit={this.handleSubmit}>
                                <div className="subscribe__form-inner">
                                    <div className="subscribe__row">
                                        <label htmlFor="subscribe_mail" className="hidden">Email</label>

                                        <input type="email" id="mail" name="mail" value={email || ''} placeholder="Votre email" className="subscribe__field" onChange={this.handleChange} />
                                    </div>{/* <!-- /.subscribe__row --> */}

                                    <div className="subscribe__actions">
                                        <button type="submit" value="GO" className="subscribe__btn">Je m’inscris</button>
                                    </div>{/* <!-- /.subscribe__actions --> */}
                                </div>{/* <!-- /.subscribe__form-inner --> */}
                            </form>
                        </div>{/* <!-- /.subscribe__form --> */}
                    </div>{/* <!-- /.subscribe__inner --> */}
                </div>{/* <!-- /.shell --> */}
            </div>/* <!-- /.subscribe --> */
        );
    }
}
export default withI18next(['common'])(Newsletter);
